<template>
    <div class="container mt-4">
        <div class="row">
            <div class="col-3">
                <div class="card shadow-sm rounded-3">
                    <div class="card-body p-4 text-center">
                        <img :src="$store.state.user.photo" alt="" class="img-fluid rounded-circle">
                    </div>
                    <div class="card-body">
                        <hr>
                        <div class="username">{{ $store.state.user.nickname }}</div>
                        <hr>
                        <div class="ra">
                            <span>排 名：</span>
                            <span class="text-muted">{{ $store.state.user.ranking }}</span>
                        </div>
                        <div class="ra">
                            <span>天梯分：</span>
                            <span class="text-muted">{{ $store.state.user.rating }}</span>
                        </div>
                        <div class="ra">
                            <span>贪吃蛇：</span>
                            <span class="text-muted">{{ $store.state.user.score }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-9">
                <div class="card shadow-sm rounded-3">
                    <div class="card-header">
                        <span class="fs-4">个人信息</span>
                    </div>
                    <div class="card-body userinfo">
                        <div class="usertext">
                            <span>用户名：</span>
                            <span class="text-muted">{{ $store.state.user.username }}</span>
                        </div>
                        <div class="usertext">
                            <span>昵 称：</span>
                            <span class="text-muted">{{ $store.state.user.nickname }}</span>
                        </div>
                        <div class="usertext">
                            <span>个人介绍：</span>
                            <span class="text-muted">{{ $store.state.user.description }}</span>
                        </div>
                        <div class="usertext">
                            <span>电话号码：</span>
                            <span class="text-muted">{{ $store.state.user.phone }}</span>
                        </div>
                        <div class="usertext">
                            <span>邮箱：</span>
                            <span class="text-muted">{{ $store.state.user.email }}</span>
                        </div>
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                            data-bs-target="#edit-info-modal" @click="openEditInfoModal">
                            编辑信息
                        </button>
                        <button type="button" class="btn btn-secondary" data-bs-toggle="modal"
                            data-bs-target="#change-password-modal" @click="clearErrorMessage">
                            修改密码
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- 编辑信息模态框 -->
        <div class="modal fade" id="edit-info-modal" tabindex="-1" aria-labelledby="editInfoModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="editInfoModalLabel">编辑信息</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="updateInfo">
                            <div class="mb-3">
                                <label for="username" class="form-label">用户名</label>
                                <input type="text" class="form-control" id="username" v-model="userinfo.username">
                            </div>
                            <div class="mb-3">
                                <label for="nickname" class="form-label">昵 称</label>
                                <input type="text" class="form-control" id="nickname" v-model="userinfo.nickname">
                            </div>
                            <div class="mb-3">
                                <label for="description" class="form-label">个人介绍</label>
                                <textarea class="form-control" id="description"
                                    v-model="userinfo.description"></textarea>
                            </div>
                            <div class="mb-3">
                                <label for="phone" class="form-label">电话号码</label>
                                <input type="text" class="form-control" id="phone" v-model="userinfo.phone">
                            </div>
                            <div class="mb-3">
                                <label for="email" class="form-label">邮箱</label>
                                <input type="email" class="form-control" id="email" v-model="userinfo.email">
                            </div>
                            <div class="error-message">{{ userinfo.message }}</div>
                            <button type="submit" class="btn btn-primary">保存修改</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!-- 修改密码模态框 -->
        <div class="modal fade" id="change-password-modal" tabindex="-1" aria-labelledby="changePasswordModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="changePasswordModalLabel">修改密码</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="updatePassword">
                            <div class="mb-3">
                                <label for="oldPassword" class="form-label">旧密码</label>
                                <input type="password" class="form-control" id="oldPassword"
                                    v-model="userinfo.oldPassword">
                            </div>
                            <div class="mb-3">
                                <label for="newPassword" class="form-label">新密码</label>
                                <input type="password" class="form-control" id="newPassword"
                                    v-model="userinfo.newPassword">
                            </div>
                            <div class="mb-3">
                                <label for="confirmedNewPassword" class="form-label">确认新密码</label>
                                <input type="password" class="form-control" id="confirmedNewPassword"
                                    v-model="userinfo.confirmedNewPassword">
                            </div>
                            <div class="error-message">{{ userinfo.message }}</div>
                            <button type="submit" class="btn btn-primary">保存修改</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!-- 成功信息模态框 -->
        <div class="modal fade" id="success-modal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="successModalLabel">{{ successMessage }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        {{ successMessage }}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">关闭</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref } from 'vue'
import $ from 'jquery'
import { useStore } from 'vuex'
import { Modal } from 'bootstrap/dist/js/bootstrap'
import { useRouter } from 'vue-router'

export default {
    setup() {
        const store = useStore();
        const router = useRouter();

        const userinfo = reactive({
            id: store.state.user.id,
            username: store.state.user.username,
            nickname: store.state.user.nickname,
            description: store.state.user.description,
            phone: store.state.user.phone,
            email: store.state.user.email,
            oldPassword: '',
            newPassword: '',
            confirmedNewPassword: '',
            message: ''
        });

        const successMessage = ref('');

        const showSuccessModal = (message) => {
            successMessage.value = message;
            const successModal = new Modal(document.getElementById('success-modal'));
            successModal.show();
        };

        const updateInfo = () => {
            userinfo.message = "";
            $.ajax({
                url: "https://www.czytqg.com/api/user/account/updateInfo/",
                type: "post",
                data: {
                    id: userinfo.id,
                    username: userinfo.username,
                    nickname: userinfo.nickname,
                    description: userinfo.description,
                    phone: userinfo.phone,
                    email: userinfo.email,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.message === "success") {
                        store.commit('setUser', {
                            ...store.state.user,
                            username: userinfo.username,
                            nickname: userinfo.nickname,
                            description: userinfo.description,
                            phone: userinfo.phone,
                            email: userinfo.email
                        });
                        Modal.getInstance(document.getElementById('edit-info-modal')).hide();
                        showSuccessModal('信息修改成功');
                    } else {
                        userinfo.message = resp.message;
                    }
                },
                error() {
                    userinfo.message = "服务器错误，请稍后再试。";
                }
            });
        };

        const updatePassword = () => {
            userinfo.message = "";
            $.ajax({
                url: "https://www.czytqg.com/api/user/account/updatePassword/",
                type: "post",
                data: {
                    id: userinfo.id,
                    oldPassword: userinfo.oldPassword,
                    newPassword: userinfo.newPassword,
                    confirmedNewPassword: userinfo.confirmedNewPassword,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.message === "success") {
                        userinfo.oldPassword = '';
                        userinfo.newPassword = '';
                        userinfo.confirmedNewPassword = '';
                        Modal.getInstance(document.getElementById('change-password-modal')).hide();
                        showSuccessModal('密码修改成功');
                        store.dispatch("logout").then(() => {
                            router.push('/user/account/login/');
                        });
                    } else {
                        userinfo.message = resp.message;
                    }
                },
                error() {
                    userinfo.message = "服务器错误，请稍后再试。";
                }
            });
        };

        const clearErrorMessage = () => {
            userinfo.message = "";
        };

        const openEditInfoModal = () => {
            clearErrorMessage();
            // 同步 store 的用户数据到 userinfo
            userinfo.id = store.state.user.id;
            userinfo.username = store.state.user.username;
            userinfo.nickname = store.state.user.nickname;
            userinfo.description = store.state.user.description;
            userinfo.phone = store.state.user.phone;
            userinfo.email = store.state.user.email;
        };

        return {
            userinfo,
            updateInfo,
            updatePassword,
            clearErrorMessage,
            openEditInfoModal,
            successMessage
        }
    }
}
</script>

<style scoped>
.error-message {
    color: red;
}

.username {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin: 26px auto;
}

.ra {
    width: 60%;
    margin: 10px auto;
    text-align: left;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.userinfo {
    padding: 45px;
}

.usertext {
    margin: 15px auto;
    font-size: large;
}

.usertext>span:first-child {
    font-weight: bold;
}

.btn {
    margin: 15px 30px 10px 0;
}

.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
}

.modal.show .modal-dialog {
    transform: translateY(0);
}
</style>
