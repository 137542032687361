<template>
    <div class="container content-field">
        <div class="row mt-4">
            <div class="col-9">
                <div class="card shadow-sm rounded-3">
                    <div class="card-body">
                        <table class="table table-striped table-hover text-left">
                            <thead>
                                <tr>
                                    <th>玩家A</th>
                                    <th>玩家B</th>
                                    <th>对战结果</th>
                                    <th>对战时间</th>
                                    <th>操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="record in records" :key="record.record.id">
                                    <td>
                                        <img :src="record.a_photo" alt="" class="record-user-photo">
                                        &nbsp;
                                        <span class="record-user-username">{{ record.a_username }}</span>
                                    </td>
                                    <td>
                                        <img :src="record.b_photo" alt="" class="record-user-photo">
                                        &nbsp;
                                        <span class="record-user-username">{{ record.b_username }}</span>
                                    </td>
                                    <td>{{ record.result }}</td>
                                    <td>{{ record.record.createtime }}</td>
                                    <td>
                                        <button @click="open_record_content(record.record.id)" type="button"
                                            class="btn btn-secondary">查看录像</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <nav aria-label="...">
                            <ul class="pagination" style="float: right;">
                                <li class="page-item" @click="click_page(-2)">
                                    <a class="page-link" href="#">前一页</a>
                                </li>
                                <li :class="'page-item ' + page.is_active" v-for="page in pages" :key="page.number"
                                    @click="click_page(page.number)">
                                    <a class="page-link" href="#">{{ page.number }}</a>
                                </li>
                                <li class="page-item" @click="click_page(-1)">
                                    <a class="page-link" href="#">后一页</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="card shadow-sm rounded-3">
                    <div class="card-body text-center">
                        <img :src="$store.state.user.photo" alt="User Photo" class="img-fluid rounded-circle">
                    </div>
                    <div class="card-body text-center">
                        <hr>
                        <div class="username">{{ $store.state.user.nickname }}</div>
                        <hr>
                        <div class="ra">
                            <span>排名：</span>
                            <span class="text-muted">{{ $store.state.user.ranking }}</span>
                        </div>
                        <div class="ra">
                            <span>天梯分：</span>
                            <span class="text-muted">{{ $store.state.user.rating }}</span>
                        </div>
                        <button type="button" class="btn btn-info btn-lg mybtn" @click="fetchMyRecords">我的记录</button>
                        <button type="button" class="btn btn-warning btn-lg mybtn"
                            @click="fetchAllRecords">全部记录</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { ref } from 'vue';
import $ from 'jquery';
import router from '../../router/index';

export default {
    setup() {
        const store = useStore();
        let records = ref([]);
        let current_page = 1;
        let total_records = 0;
        let pages = ref([]);
        let isMyRecords = ref(false);  // Flag to check if "My Records" is being viewed

        const click_page = page => {
            if (page === -2) page = current_page - 1;
            else if (page === -1) page = current_page + 1;
            let max_pages = parseInt(Math.ceil(total_records / 9));

            if (page >= 1 && page <= max_pages) {
                pull_page(page);
            }
        }

        const udpate_pages = () => {
            let max_pages = parseInt(Math.ceil(total_records / 9));
            let new_pages = [];
            for (let i = current_page - 2; i <= current_page + 2; i++) {
                if (i >= 1 && i <= max_pages) {
                    new_pages.push({
                        number: i,
                        is_active: i === current_page ? "active" : "",
                    });
                }
            }
            pages.value = new_pages;
        }

        const pull_page = page => {
            current_page = page;
            let url = isMyRecords.value ? "https://www.czytqg.com/api/record/getmylist/" : "https://www.czytqg.com/api/record/getlist/";
            let data = {
                page
            };
            if (isMyRecords.value) {
                data.id = store.state.user.id;
            }

            $.ajax({
                url,
                data,
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    records.value = resp.records;
                    total_records = resp.records_count;
                    udpate_pages();
                },
                error(resp) {
                    console.log(resp);
                }
            })
        }

        const fetchMyRecords = () => {
            isMyRecords.value = true;
            current_page = 1;
            pull_page(current_page);
        }

        const fetchAllRecords = () => {
            isMyRecords.value = false;
            current_page = 1;
            pull_page(current_page);
        }

        pull_page(current_page);

        const stringTo2D = map => {
            let g = [];
            for (let i = 0, k = 0; i < 13; i++) {
                let line = [];
                for (let j = 0; j < 14; j++, k++) {
                    if (map[k] === '0') line.push(0);
                    else line.push(1);
                }
                g.push(line);
            }
            return g;
        }

        const open_record_content = recordId => {
            for (const record of records.value) {
                if (record.record.id === recordId) {
                    store.commit("updateIsRecord", true);
                    store.commit("updateGame", {
                        map: stringTo2D(record.record.map),
                        a_id: record.record.aid,
                        a_sx: record.record.asx,
                        a_sy: record.record.asy,
                        b_id: record.record.bid,
                        b_sx: record.record.bsx,
                        b_sy: record.record.bsy,
                    });
                    store.commit("updateSteps", {
                        a_steps: record.record.asteps,
                        b_steps: record.record.bsteps,
                    });
                    store.commit("updateRecordLoser", record.record.loser);
                    router.push({
                        name: "record_content",
                        params: {
                            recordId
                        }
                    })
                    break;
                }
            }
        }

        return {
            records,
            open_record_content,
            pages,
            click_page,
            fetchMyRecords,
            fetchAllRecords
        }
    }
}
</script>

<style scoped>

img.record-user-photo {
    width: 4vh;
    border-radius: 50%;
}

.username {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin: 26px auto;
}

.ra {
    width: 60%;
    margin: 10px auto;
    text-align: left;
}

.mybtn {
    margin: 10px 8px;
    padding: 6px 15px;
    color: white;
}
</style>