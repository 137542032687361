<template>
    <div class="container content-field">
        <div class="card">
            <div class="card-body">
                <div class="padd row justify-content-md-center">
                    <div class="col-12">
                        <form @submit.prevent="register">
                            <div class="mb-3">
                                <label for="username" class="form-label">用户名：</label>
                                <input v-model="username" type="text" class="form-control" id="username"
                                    placeholder="请输入用户名">
                            </div>
                            <div class="mb-3">
                                <label for="password" class="form-label">密码：</label>
                                <input v-model="password" type="password" class="form-control" id="password"
                                    placeholder="请输入密码">
                            </div>
                            <div class="mb-3">
                                <label for="confirmedPassword" class="form-label">确认密码：</label>
                                <input v-model="confirmedPassword" type="password" class="form-control"
                                    id="confirmedPassword" placeholder="请再次输入密码">
                            </div>
                            <div class="message">{{ message }}</div>
                            <button type="submit" class="btn btn-primary">注 册</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import router from '../../../router/index'
import $ from 'jquery'

export default {
    setup() {
        let username = ref('');
        let password = ref('');
        let confirmedPassword = ref('');
        let message = ref('');

        const register = () => {
            $.ajax({
                url: "https://www.czytqg.com/api/user/account/register/",
                type: "post",
                data: {
                    username: username.value,
                    password: password.value,
                    confirmedPassword: confirmedPassword.value,
                },
                success(resp) {
                    if (resp.message === "success") {
                        router.push({ name: "user_account_login" });
                    } else {
                        message.value = resp.message;
                    }
                },
            });
        }

        return {
            username,
            password,
            confirmedPassword,
            message,
            register,
        }
    }
}
</script>

<style scoped>
div.content-field {
    margin-top: 50px;
    max-width: 500px;
}

.padd {
    padding: 24px;
}

button {
    margin-top: 20px;
    width: 100%;
    height: 40px;
}

div.message {
    color: red;
}
</style>