<template>
  <NavBar />
  <router-view></router-view>
</template>

<script>
import NavBar from './components/NavBar.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap"
// import $ from 'jquery'

export default {
  components: {
    NavBar
  },
  setup() {
    // $.ajax({
    //   url: "http://127.0.0.1:3000/api/user/account/updateScore/",
    //   type: "post",
    //   data: {
    //     id: 1,
    //     score: 4
    //   },
    //   headers: {
    //     Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI2ZjNlZDhjNzcwZjk0ZmYzYTdkYzQxZTZiNjIyOTdmYyIsInN1YiI6IjEiLCJpc3MiOiJzZyIsImlhdCI6MTcyNTE4MzQ4MywiZXhwIjoxNzI2MzkzMDgzfQ.pYqD-8R16jf8KxPMSOyhGT5hRTPM5wNtYVECRhp1rMs"
    //   },
    //   success(resp) {
    //     console.log(resp);
    //   },
    //   error(resp) {
    //     console.log(resp);
    //   }
    // });
    // $.ajax({
    //   url: "http://127.0.0.1:3000/api/user/account/token/",
    //   type: "post",
    //   data: {
    //     username: "CZY",
    //     password: "159674832",
    //   },
    //   success(resp) {
    //     console.log(resp);
    //   },
    //   error(resp) {
    //     console.log(resp);
    //   }
    // })
    // $.ajax({
    //   url: "http://127.0.0.1:3000/user/account/info/",
    //   type: "get",
    //   headers: {
    //     Authorization: "Bearer " + "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIyYmU5ZDAzNWNkYzM0NTdiYWY2NjEyNGJlY2FkZTI4MCIsInN1YiI6IjEzIiwiaXNzIjoic2ciLCJpYXQiOjE3MjAzNjEwMzQsImV4cCI6MTcyMTU3MDYzNH0.Qc6noQ9voSmVr8iXPOCzADK_6pqv5OgV0Lyg_OTmYFk"
    //   },
    //   success(resp) {
    //     console.log(resp);
    //   },
    //   error(resp) {
    //     console.log(resp);
    //   }
    // })
    // $.ajax({
    //   url: "http://127.0.0.1:3000/user/account/updateInfo/",
    //   type: "post",
    //   headers: {
    //     Authorization: "Bearer " + "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJiZTk1OTIxMTI2NzM0MWNhODY4MDYwMTI2MWQzY2MyNiIsInN1YiI6IjE5IiwiaXNzIjoic2ciLCJpYXQiOjE3MjA3ODkwMzYsImV4cCI6MTcyMTk5ODYzNn0.5T6TYmS2fizPidE66CaWjqNTsTe3MLHuPttQawtfxWA"
    //   },
    //   data: {
    //     id: 19,
    //     username: "test2",
    //     description: "hahahahahhaha",
    //     phone: "1780000123",
    //     email: "123456789@321.com",
    //   },
    //   success(resp) {
    //     console.log(resp);
    //   },
    //   error(resp) {
    //     console.log(resp);
    //   }
    // })
    // $.ajax({
    //   url: "http://127.0.0.1:3000/user/account/updatePassword/",
    //   type: "post",
    //   headers: {
    //     Authorization: "Bearer " + "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJiZTk1OTIxMTI2NzM0MWNhODY4MDYwMTI2MWQzY2MyNiIsInN1YiI6IjE5IiwiaXNzIjoic2ciLCJpYXQiOjE3MjA3ODkwMzYsImV4cCI6MTcyMTk5ODYzNn0.5T6TYmS2fizPidE66CaWjqNTsTe3MLHuPttQawtfxWA"
    //   },
    //   data: {
    //     id: 19,
    //     oldPassword: "123456",
    //     newPassword: "test2",
    //     confirmedNewPassword: "test2",
    //   },
    //   success(resp) {
    //     console.log(resp);
    //   },
    //   error(resp) {
    //     console.log(resp);
    //   }
    // })

    // $.ajax({
    //   url: "http://127.0.0.1:3000/record/getmylist/",
    //   data: {
    //     page: 1,
    //     id: 19
    //   },
    //   type: "get",
    //   headers: {
    //     Authorization: "Bearer " + "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJjNmI2NDhmODMyOTE0Yjg4YjUwNDA5ZWIxNWY1MjA1NCIsInN1YiI6IjE5IiwiaXNzIjoic2ciLCJpYXQiOjE3MjExMjk3NjcsImV4cCI6MTcyMjMzOTM2N30.on7DNPqAjJDy_JmrLdDyGSbd7oEJ7cjeKucgre_IHRY",
    //   },
    //   success(resp) {
    //     console.log(resp);
    //   },
    //   error(resp) {
    //     console.log(resp);
    //   }
    // })
  }
}
</script>

<style>
body {
  background-image: url("@/assets/images/background.jpg");
  background-size: cover;
}
</style>
