<template>
    <div class="container">
        <div class="row  mt-4">
            <div class="col-9">
                <div class="card shadow-sm rounded-3">
                    <div class="card-body">
                        <table class="table table-striped table-hover text-left">
                            <thead>
                                <tr>
                                    <th>排名</th>
                                    <th>玩家</th>
                                    <th>天梯分</th>
                                    <th>贪吃蛇</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="user in users" :key="user.id">
                                    <td>{{ user.ranking }}</td>
                                    <td>
                                        <img :src="user.photo" alt="" class="record-user-photo">
                                        &nbsp;
                                        <span class="record-user-username">{{ user.username }}</span>
                                    </td>
                                    <td>{{ user.rating }}</td>
                                    <td>{{ user.score }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <nav aria-label="Page navigation">
                            <ul class="pagination justify-content-end">
                                <li class="page-item" @click="click_page(current_page - 1)"
                                    :class="{ disabled: current_page === 1 }">
                                    <a class="page-link" href="#">前一页</a>
                                </li>
                                <li :class="'page-item ' + page.is_active" v-for="page in pages" :key="page.number"
                                    @click="click_page(page.number)">
                                    <a class="page-link" href="#">{{ page.number }}</a>
                                </li>
                                <li class="page-item" @click="click_page(current_page + 1)"
                                    :class="{ disabled: current_page === max_pages }">
                                    <a class="page-link" href="#">后一页</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="card shadow-sm rounded-3">
                    <div class="card-body text-center">
                        <img :src="$store.state.user.photo" alt="User Photo" class="img-fluid rounded-circle">
                    </div>
                    <div class="card-body text-center">
                        <hr>
                        <div class="username">{{ $store.state.user.nickname }}</div>
                        <hr>
                        <div class="ra">
                            <span>排 名：</span>
                            <span class="text-muted">{{ $store.state.user.ranking }}</span>
                        </div>
                        <div class="ra">
                            <span>天梯分：</span>
                            <span class="text-muted">{{ $store.state.user.rating }}</span>
                        </div>
                        <div class="ra">
                            <span>贪吃蛇：</span>
                            <span class="text-muted">{{ $store.state.user.score }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { ref } from 'vue';
import $ from 'jquery';

export default {
    setup() {
        const store = useStore();
        const users = ref([]);
        let current_page = ref(1);
        const total_users = ref(0);
        const pages = ref([]);
        const max_pages = ref(0);

        const click_page = page => {
            if (page >= 1 && page <= max_pages.value) {
                pull_page(page);
            }
        }

        const update_pages = () => {
            max_pages.value = Math.ceil(total_users.value / 10);
            const new_pages = [];
            for (let i = current_page.value - 2; i <= current_page.value + 2; i++) {
                if (i >= 1 && i <= max_pages.value) {
                    new_pages.push({
                        number: i,
                        is_active: i === current_page.value ? "active" : "",
                    });
                }
            }
            pages.value = new_pages;
        }

        const pull_page = page => {
            current_page.value = page;
            $.ajax({
                url: "https://www.czytqg.com/api/ranklist/getlist/",
                data: {
                    page,
                },
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    users.value = resp.users;
                    total_users.value = resp.users_count;
                    update_pages();
                },
                error(resp) {
                    console.log(resp);
                }
            })
        }

        pull_page(current_page.value);

        return {
            users,
            pages,
            click_page,
            current_page,
            max_pages
        }
    }
}
</script>

<style scoped>
img.record-user-photo {
    width: 4vh;
    border-radius: 50%;
}

/* .container {
    padding: 20px;
} */

.pagination .disabled .page-link {
    pointer-events: none;
    cursor: default;
}

.username {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin: 26px auto;
}

.ra {
    width: 60%;
    margin: 10px auto;
    text-align: left;
}
</style>