<template>
    <div>
        <GameGround :gameTitle="gameTitle" :gameModes="gameModes" @mode-clicked="handleModeClicked" />
    </div>
</template>

<script>
import GameGround from '../../components/GameGround.vue';
import { useRouter } from 'vue-router';

export default {
    components: {
        GameGround
    },
    data() {
        return {
            gameTitle: "贪吃蛇",
            gameModes: ['开始游戏', '排行榜', '游戏规则', '返回首页']
        };
    },
    setup() {
        const router = useRouter();

        const goToGameView = () => {
            router.push({ name: 'snake_game' });
        };
        const goToRanklistView = () => {
            router.push({ name: 'ranklist_index' });
        };
        const goToHome = () => {
            router.push({ name: 'home' });
        };
        return {
            goToGameView,
            goToRanklistView,
            goToHome
        };
    },
    methods: {
        handleModeClicked(mode) {
            if (mode === '开始游戏') {
                this.goToGameView();
            } else if (mode === '排行榜') {
                this.goToRanklistView();
            } else if (mode === '游戏规则') {
                this.gameModes = ['玩家通过 wasd 来控制方向。操控一条蛇不断吞下苹果，同时蛇身随着吞下的苹果不断变长，当蛇头撞到蛇身或障壁时游戏结束。', '返回'];
            } else if (mode === '返回') {
                this.gameModes = ['开始游戏', '排行榜', '游戏规则', '返回首页'];
            } else if (mode === '返回首页') {
                this.goToHome();
            }
        }
    }
};
</script>

<style scoped>
.difficulty,
.matching-interface,
.game-rules {
    margin: 20px;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.difficulty {
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease;
}

.difficulty:hover {
    transform: scale(1.1);
    background-color: rgba(255, 215, 0, 0.8);
}
</style>