<template>
    <div class="footer">
        <a :href="beianLink" target="_blank" class="beian-link">{{ beian }}</a>
    </div>
</template>

<script>
export default {
    data() {
        return {
            beian: '鲁ICP备2024108929号',
            beianLink: 'http://beian.miit.gov.cn/'
        };
    }
};
</script>

<style scoped>
.footer {
    text-align: center;
    position: fixed;
    padding: 5px 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgb(243, 244, 246);
    box-sizing: border-box;
    z-index: 999;
}

.beian-link {
    text-decoration: none;
    color: rgb(102, 102, 102);
}
</style>