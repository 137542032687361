<template>
    <div class="gameground">
        <div class="backimg">
            <div class="row">
                <div class="col-6">
                    <div v-for="(mode, index) in gameModes" :key="index" class="gametext"
                        @click="handleModeClick(mode)">
                        {{ mode }}
                    </div>
                </div>
                <div class="col-6">
                    <div class="gametitle">
                        {{ gameTitle }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GameGround',
    props: {
        gameTitle: {
            type: String,
            required: true
        },
        gameModes: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    methods: {
        handleModeClick(mode) {
            this.$emit('mode-clicked', mode);
        }
    }
}
</script>

<style scoped>
.gameground {
    width: 60vw;
    height: 75vh;
    margin: 40px auto;
    background-color: rgba(100, 100, 100, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    position: relative;
}

.backimg {
    width: 95%;
    height: 90%;
    background-image: url("../assets/images/snake2game.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}

.row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em;
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.5);
}

.gametext {
    margin: 1em 1em 0 1.5em;
    color: #f1f1f1;
    font-size: 1.5rem;
    font-weight: bold;
    font-style: italic;
    transition: transform 0.2s ease, color 0.2s ease;
    cursor: pointer;
}

.gametext:hover {
    transform: scale(1.2);
    color: #ffd700;
}

.gametitle {
    margin: 0.5em;
    color: #fff;
    font-size: 3rem;
    font-weight: bold;
    font-style: italic;
    text-align: right;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}
</style>
