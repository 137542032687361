<template>
    <nav class="navbar navbar-expand-lg bg-success" data-bs-theme="dark">
        <div class="container">
            <router-link class="navbar-brand" :to="{ name: 'home' }">
                <img src="../assets/images/img.png" alt="" width="32" height="32" class="d-inline-block align-text-top">
                King Of Bots
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
                aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarText">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <router-link :class="route_name == 'home' ? 'nav-link active' : 'nav-link'"
                            :to="{ name: 'home' }">首页</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :class="route_name == 'snake2_ready' ? 'nav-link active' : 'nav-link'"
                            :to="{ name: 'snake2_ready' }">蛇蛇大作战</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :class="route_name == 'snake_ready' ? 'nav-link active' : 'nav-link'"
                            :to="{ name: 'snake_ready' }">贪吃蛇</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :class="route_name == 'record_index' ? 'nav-link active' : 'nav-link'"
                            :to="{ name: 'record_index' }">对局列表</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :class="route_name == 'ranklist_index' ? 'nav-link active' : 'nav-link'"
                            :to="{ name: 'ranklist_index' }">排行榜</router-link>
                    </li>
                </ul>
                <ul class="navbar-nav" v-if="$store.state.user.is_login">
                    <li class="nav-item dropdown">
                        <a class="user nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbarDropdown"
                            role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img :src="$store.state.user.photo" width="32" height="32"
                                class="userphoto rounded-circle me-2">
                            {{ $store.state.user.nickname }}
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li>
                                <router-link class="dropdown-item"
                                    :to="{ name: 'user_account_info' }">个人中心</router-link>
                            </li>
                            <li>
                                <router-link class="dropdown-item" :to="{ name: 'user_bot_index' }">我的Bot</router-link>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li><a class="dropdown-item" href="#" @click="logout">退出</a></li>
                        </ul>
                    </li>
                </ul>
                <ul class="navbar-nav" v-else-if="!$store.state.user.pulling_info">
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'user_account_login' }" role="button">
                            登录
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'user_account_register' }" role="button">
                            注册
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { useStore } from 'vuex';

export default {
    setup() {
        const store = useStore();
        const route = useRoute();
        let route_name = computed(() => route.name)

        const logout = () => {
            store.dispatch("logout");
        }

        return {
            route_name,
            logout
        }
    }
}
</script>

<style scoped>
.container {
    margin: auto 60px;
}

.navbar-brand:hover {
    color: #fff;
    /* box-shadow: 2px 2px 10px whitesmoke; */
    border-radius: 10px;
    /* padding: 5px 10px; */
    font-weight: bold;
    font-size: 20px;
}

.userphoto:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
</style>