<template>
    <div class="matchground">
        <div class="row">
            <div class="col-4">
                <div class="user-photo">
                    <img :src="$store.state.user.photo" alt="">
                </div>
                <div class="user-username">
                    {{ $store.state.user.nickname }}
                </div>
            </div>
            <div class="col-4">
            </div>
            <div class="col-4">
                <div class="user-photo">
                    <img :src="opponent.photo" alt="">
                </div>
                <div class="user-username">
                    {{ opponent.nickname }}
                </div>
            </div>
            <div class="col-12" style="text-align: center; padding-top: 15vh;">
                <button @click="goToReadyView" type="button" class="btn btn-dark btn-lg">返回</button>
                <button @click="click_start_btn" type="button" class="btn btn-warning btn-lg">
                    开始战斗
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
    props: {
        difficulty: {
            type: String,
            default: null
        }
    },
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const bots = ref([]);
        const select_bot = ref("-1");

        const opponent = computed(() => {
            let opponentInfo = { username: "未知对手", photo: "https://cdn.acwing.com/media/article/image/2022/08/09/1_1db2488f17-anonymous.png" };
            if (props.difficulty === 'easy') {
                opponentInfo = { username: "简单难度", photo: require('../assets/images/easybot.png') };
            } else if (props.difficulty === 'common') {
                opponentInfo = { username: "中等难度", photo: require('../assets/images/commonbot.png') };
            } else if (props.difficulty === 'hard') {
                opponentInfo = { username: "困难难度", photo: require('../assets/images/hardbot.png') };
            }
            return opponentInfo;
        });

        const click_start_btn = () => {
            store.state.pk.socket.send(JSON.stringify({
                event: "start-botmatching",
                bot_id: select_bot.value,
                difficulty: props.difficulty
            }));
        };

        const goToReadyView = () => {
            router.push({ name: 'snake2_ready' });
        };

        return {
            click_start_btn,
            bots,
            select_bot,
            goToReadyView,
            opponent
        };
    }
};
</script>

<style scoped>
div.matchground {
    width: 60vw;
    height: 75vh;
    margin: 40px auto;
    border-radius: 15px;
    background-color: rgba(120, 120, 120, 0.7);
}

div.user-photo {
    text-align: center;
    padding-top: 10vh;
}

div.user-photo>img {
    border-radius: 50%;
    width: 20vh;
}

div.user-username {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: white;
    padding-top: 2vh;
}

div.user-select-bot {
    padding-top: 20vh;
}

div.user-select-bot>select {
    width: 60%;
    margin: 0 auto;
}

.btn {
    margin: 10px 50px;
}

.difficulty {
    margin: 20px 0;
    font-size: 18px;
    color: yellow;
}
</style>
