<template>
    <div class="score-board">
        <div class="apple">
            <img src="../../assets/images/snake1/apple.png" alt="">
            <div>{{ $store.state.snake1.score }}</div>
        </div>
        <div class="cup">
            <img src="../../assets/images/snake1/cup.png" alt="">
            <div>{{ $store.state.user.score }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ScoreBoard",
}
</script>


<style scoped>
.score-board {
    height: 8vh;
    width: 100%;
    background-color: #4A752C;
    display: flex;
    justify-content: space-around;
    user-select: none;
}

.apple {
    display: flex;
    align-items: center;
}

.apple>img {
    width: 6vh;
    height: 6vh;
}

.apple>div {
    color: white;
    font-weight: bold;
    font-size: 2.5vh;
    margin-left: 2vh;
}

.cup {
    display: flex;
    align-items: center;
}

.cup>img {
    width: 5vh;
    height: 5.5vh;
}

.cup>div {
    color: white;
    font-weight: bold;
    font-size: 2.5vh;
    margin-left: 2vh;
}
</style>