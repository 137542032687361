import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PkIndexView from '../views/pk/PkindexView.vue'
import PkBotIndexView from '../views/pk/PkBotIndexView.vue'
import RecordIndexView from '../views/record/RecordIndexView.vue'
import RecordContentView from '../views/record/RecordContentView.vue'
import RanklistIndexView from '../views/ranklist/RanklistIndexView.vue'
import UserBotIndexView from '../views/user/bot/UserbotindexView.vue'
import NotFound from '../views/error/NotFound.vue'
import UserAccountLoginView from '../views/user/account/UserAccountLoginView.vue'
import UserAccountRegisterView from '../views/user/account/UserAccountRegisterView.vue'
import SnakeView from '../views/snake1/SnakeView.vue'
import snakeReadyView from '../views/game/snakeReadyView.vue'
import snake2ReadyView from '../views/game/snake2ReadyView.vue'
import UserAccountInfoView from '../views/user/account/UserAccountInfoView.vue'
import UserAccountQQReceiveCodeView from '../views/user/account/UserAccountQQReceiveCodeView.vue'
import store from '../store/index'

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/snake2/pk/",
    name: "pk_index",
    component: PkIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/snake2/pkbot/",
    name: "pkbot_index",
    component: PkBotIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/snake/game/",
    name: "snake_game",
    component: SnakeView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/snake/ready/",
    name: "snake_ready",
    component: snakeReadyView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/snake2/ready/",
    name: "snake2_ready",
    component: snake2ReadyView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/record/",
    name: "record_index",
    component: RecordIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/record/:recordId/",
    name: "record_content",
    component: RecordContentView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/ranklist/",
    name: "ranklist_index",
    component: RanklistIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/user/bot/",
    name: "user_bot_index",
    component: UserBotIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/user/account/login/",
    name: "user_account_login",
    component: UserAccountLoginView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/user/account/register/",
    name: "user_account_register",
    component: UserAccountRegisterView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/user/account/qq/receive_code",
    name: "user_account_qq_receive_code",
    component: UserAccountQQReceiveCodeView,
    meta: {
      requestAuth: false
    }
  },
  {
    path: "/user/account/info/",
    name: "user_account_info",
    component: UserAccountInfoView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/test/",
    name: "test",
    component: NotFound,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/404/",
    name: "404",
    component: NotFound,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404/"
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {

  let flag = 1;
  const jwt_token = localStorage.getItem("jwt_token");

  if (jwt_token) {
    store.commit("updateToken", jwt_token);
    store.dispatch("getinfo", {
      success() {
      },
      error() {
        localStorage.removeItem('jwt_token');
        store.dispatch("logout");
        alert("token无效，请重新登录！");
        router.push({ name: 'user_account_login' });
      }
    })
  } else {
    flag = 2;
  }

  if (to.meta.requestAuth && !store.state.user.is_login) {
    if (flag === 1) {
      next();
    } else {
      alert("请先进行登录！");
      next({ name: "user_account_login" });
    }
  } else {
    next();
  }
})

export default router
