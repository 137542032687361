<template>
    <div>
        <GameGround :gameTitle="gameTitle" :gameModes="gameModes" @mode-clicked="handleModeClicked" />
    </div>
</template>

<script>
import GameGround from '../../components/GameGround.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
    components: {
        GameGround
    },
    data() {
        return {
            gameTitle: "蛇蛇大作战",
            gameModes: ['人机对战', '天梯匹配', '排行榜', '游戏规则', '返回首页']
        };
    },
    setup() {
        const router = useRouter();
        const store = useStore();

        const goToPkBotView = (difficulty = null) => {
            if (difficulty) {
                store.commit('updateDifficulty', difficulty);
            }
            router.push({ name: 'pkbot_index' });
        };
        const goToPkView = () => {
            router.push({ name: 'pk_index' });
        };
        const goToRanklistView = () => {
            router.push({ name: 'ranklist_index' });
        };

        const goToHome = () => {
            router.push({ name: 'home' });
        };

        return {
            goToPkBotView,
            goToPkView,
            goToRanklistView,
            goToHome
        };
    },
    methods: {
        handleModeClicked(mode) {
            if (mode === '人机对战') {
                this.gameModes = ['简单', '普通', '困难', '返回'];
            } else if (['简单', '普通', '困难'].includes(mode)) {
                this.goToPkBotView(mode);
            } else if (mode === '天梯匹配') {
                this.goToPkView();
            } else if (mode === '排行榜') {
                this.goToRanklistView();
            } else if (mode === '游戏规则') {
                this.gameModes = ['玩家通过 wasd 来操纵地图中的一条蛇。蛇每回合前进一格。蛇的初始位置在地图的左下角或右下角，初始长度为1格，前10回合每回合长度增加1，之后每3回合长度增加1。', '返回'];
            } else if (mode === '返回') {
                this.gameModes = ['人机对战', '天梯匹配', '排行榜', '游戏规则', '返回首页'];
            } else if (mode === '返回首页') {
                this.goToHome();
            }
        }
    }
};
</script>

<style scoped>
.difficulty,
.matching-interface,
.game-rules {
    margin: 20px;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.difficulty {
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease;
}

.difficulty:hover {
    transform: scale(1.1);
    background-color: rgba(255, 215, 0, 0.8);
}
</style>
