<template>
    <div class="playground">
        <ScoreBoard />
        <GameMap />
    </div>
</template>

<script>
import ScoreBoard from '@/components/snake1/ScoreBoard';
import GameMap from '@/components/snake1/GameMap';

export default {
    name: "PlayGround",
    components: {
        ScoreBoard,
        GameMap
    }
}
</script>


<style scoped>
.playground {
    width: 56vh;
    height: 78vh;
    background-color: #578A34;
    margin: 50px auto;
}
</style>