<template>
    <ContentField>
        <div class="error">
            404 Not Found
        </div>
    </ContentField>
</template>

<script>
import ContentField from '../../components/ContentField.vue'

export default {
    components: {
        ContentField
    }
}
</script>

<style scoped>
.error {
    font-size: 32px;
    font-weight: bold;
}
</style>