<template>
    <div class="container content-field">
        <div class="card games">
            <div class="card-header">
                游戏列表
            </div>
            <div class="card-body">
                <div class="d-flex flex-wrap">
                    <div class="card game me-3 mb-3" style="width: 12rem;">
                        <router-link :class="route_name == 'snake_ready' ? 'nav-link active' : 'nav-link'"
                            :to="{ name: 'snake_ready' }" class="game-link">
                            <img class="card-img-top" src="../assets/images/Game2.png" alt="">
                            <div class="card-body">
                                <div class="card-title">贪吃蛇</div>
                                <div class="card-text">snake</div>
                            </div>
                        </router-link>
                    </div>
                    <div class="card game me-3 mb-3" style="width: 12rem;">
                        <router-link :class="route_name == 'snake2_ready' ? 'nav-link active' : 'nav-link'"
                            :to="{ name: 'snake2_ready' }" class="game-link">
                            <img class="card-img-top" src="../assets/images/Game1.png" alt="">
                            <div class="card-body">
                                <div class="card-title">蛇蛇大作战</div>
                                <div class="card-text">snake 2</div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <HomeBottom />
</template>

<script>
import HomeBottom from '@/components/HomeBottom.vue';

export default {
    components: {
        HomeBottom
    },
    data() {
        return {
            route_name: this.$route.name
        }
    }
}
</script>

<style scoped>
div.content-field {
    margin-top: 60px;
    width: 85%;
}

.games {
    background-color: rgba(80, 79, 76, 0.5);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
    font-size: 22px;
    text-align: center;
    font-weight: bold;
    color: whitesmoke;
    background-color: rgba(84, 87, 89, 0.8);
    border-radius: 10px 10px 0 0;
    padding: 10px;
}

.card-body {
    padding: 15px;
}

.game {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 24px;
}

.game:hover {
    transform: scale(1.08);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

.game-link {
    display: block;
    text-decoration: none;
    color: inherit;
}

.card-title {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
}

.card-text {
    text-align: center;
    font-size: 14px;
    color: #555;
}

.card-img-top {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.d-flex {
    justify-content: flex-start;
}

.me-3 {
    margin-right: 1rem;
}

.mb-3 {
    margin-bottom: 1rem;
}
</style>
