<template>
    <div class="gamemap" ref="div">
        <canvas ref="canvas" tabindex="0"></canvas>
        <div class="operation" v-if="$store.state.snake1.restart">
            <button class="btn btn-secondary btn-lg" @click="goback">返回</button>
            <button class="btn btn-success btn-lg" @click="restart">开始游戏</button>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { GameMap } from '@/assets/scripts/snake1/GameMap';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
    name: "GameMap",

    setup: () => {
        const router = useRouter();
        let div = ref(null);
        let canvas = ref(null);
        const store = useStore();
        let gamemap = null;

        const goback = () => {
            router.push({ name: 'snake_ready' });
        };

        onMounted(() => {
            gamemap = new GameMap(canvas.value.getContext('2d'), div.value, store);
        });

        const restart = () => {
            gamemap.restart();
        }

        return {
            div,
            canvas,
            restart,
            goback
        }
    }
}
</script>

<style scoped>
div.gamemap {
    height: calc(100% - 8vh);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

canvas {
    background-color: #AAD751;
}

div.operation {
    position: absolute;
}

.btn {
    margin: 10px;
}
</style>