<template>
    <div class="gameback">
        <PlayGround />
    </div>
</template>

<script>
import PlayGround from '../../components/snake1/PlayGround.vue'
export default {
    components: {
        PlayGround
    }
}
</script>

<style scoped>
.gameback {
    width: 60vw;
    height: 80vh;
    margin: 40px auto;
    border-radius: 15px;
    background-color: rgba(120, 120, 120, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}
</style>