<template>
    <div class="back">
        <button @click="goBack" type="button" class="btn btn-secondary btn-lg">返回</button>
    </div>

    <PlayGround />
</template>

<script>
import PlayGround from '../../components/PlayGround.vue'
import { useRouter } from 'vue-router';

export default {
    components: {
        PlayGround,
    },
    setup() {
        const router = useRouter();

        const goBack = () => {
            router.go(-1); // 返回到上一个页面
        }

        return {
            goBack
        }
    }
}
</script>

<style scoped>
.back {
    width: 50vw;
    margin: 30px auto;
}

.btn {
    margin-bottom: 15px;
    float: right;
}
</style>