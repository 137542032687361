import { GameObject } from "../GameObject";
import { Snake } from "./Snake";
import $ from 'jquery';

export class GameMap extends GameObject {
    constructor(ctx, parent, store) {
        super();

        this.ctx = ctx;
        this.parent = parent;
        this.store = store;
        this.L = 0;

        this.width = 15;
        this.height = 19;

        this.snake = new Snake(this.ctx, this);
        this.directions = [];

        this.status = "waiting";
    }

    start() {
        this.ctx.canvas.focus();

        this.ctx.canvas.addEventListener('keydown', e => {
            if (this.store.state.restart) return false;

            if (e.key === 'w' || e.key === 'ArrowUp') {
                this.directions.push(0);
                e.preventDefault();
            }
            else if (e.key === 'd' || e.key == 'ArrowRight') {
                this.directions.push(1);
                e.preventDefault();
            }
            else if (e.key === 's' || e.key === 'ArrowDown') {
                this.directions.push(2);
                e.preventDefault();
            }
            else if (e.key === 'a' || e.key === 'ArrowLeft') {
                this.directions.push(3);
                e.preventDefault();
            }

            let k = this.directions.length;
            if (k > 1 && this.directions[k - 1] === this.directions[k - 2]) {
                this.directions.pop();
            }

            while (this.directions.length > 2)
                this.directions.splice(0, 1);

            if (this.status === "waiting" && this.directions.length && this.directions[0] !== 3) {
                this.status = "playing";
                this.snake.direction = this.directions[0];
            }

        });
    }

    update_size() {
        this.L = Math.min(this.parent.clientWidth / this.width, this.parent.clientHeight / this.height) - 2;
        this.ctx.canvas.width = this.L * this.width;
        this.ctx.canvas.height = this.L * this.height;
    }

    win() {
        this.snake.color = "white";
        this.status = "win";

        // 刷新最高纪录
        if (this.store.state.snake1.score > this.store.state.user.score) {
            this.store.commit('updateRecord', this.store.state.snake1.score);
            this.updateBestScore(this.store.state.snake1.score);
            this.store.state.user.score = this.store.state.snake1.score;
        }

        this.store.commit('updateRestart', true);
    }

    lose() {
        this.snake.color = "white";
        this.status = "lose";

        // 刷新最高纪录
        if (this.store.state.snake1.score > this.store.state.user.score) {
            this.store.commit('updateRecord', this.store.state.snake1.score);
            this.updateBestScore(this.store.state.snake1.score);
            this.store.state.user.score = this.store.state.snake1.score;
        }

        this.store.commit('updateRestart', true);
    }

    updateBestScore(score) {
        $.ajax({
            url: "https://www.czytqg.com/api/user/account/updateScore/",
            type: "post",
            data: {
                id: this.store.state.user.id,
                score: score
            },
            headers: {
                Authorization: "Bearer " + this.store.state.user.token
            },
            success(resp) {
                console.log(score);
                console.log("Best score updated:", resp);
            },
            error(resp) {
                console.log("Failed to update best score:", resp);
            }
        });
    }

    restart() {
        this.store.state.snake1.score = 0;  // 重置当前分数为 0
        this.status = "waiting";
        this.snake.destroy();
        this.snake = new Snake(this.ctx, this);
        this.store.commit('updateRestart', false);
        this.ctx.canvas.focus();
    }

    update() {
        this.update_size();
        this.render();
    }

    render() {
        let color_even = "#AAD751", color_odd = "#A2D149";

        for (let i = 0; i < this.width; i++) {
            for (let j = 0; j < this.height; j++) {
                if ((i + j) % 2 == 0) {
                    this.ctx.fillStyle = color_even;
                } else {
                    this.ctx.fillStyle = color_odd;
                }
                this.ctx.fillRect(i * this.L, j * this.L, this.L, this.L);
            }
        }
    }
}
